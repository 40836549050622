/**
 * @owner @Appboy/frontend-ix
 */

import { setTag, withScope, captureMessage, Severity } from "@sentry/browser";

import actionLabelsGetter from "./actionLabelGetters";
import attributeTypeLabelsGetter from "./attributeTypeGetters";
import audienceLabelsGetter from "./audienceLabelsGetter";
import breakdownLabelsGetter from "./breakdownLabelsGetter";
import campaignTypeLabelsGetter from "./campaignTypeLabelsGetter";
import { canvasStepsLabelsGetter } from "./canvasStepsLabelsGetter";
import canvasStepsLongLabelsGetter from "./canvasStepsLabelsGetter";
import channelsLabelsGetter from "./channelLabelsGetter";
import conversionLabelsGetter from "./conversionLabelsGetter";
import dayOfWeekLabelGetter from "./dayOfWeekLabelsGetter";
import deliveryDetailsLabelsGetter from "./deliveryDetailsLabelsGetter";
import entryScheduleLabelsGetter from "./entryScheduleLabelGetter";
import errorLabelsGetter from "./errorLabelGetters";
import genderLabelsGetter from "./genderLabelsGetters";
import kpisLabelsGetter from "./kpisLabelsGetter";
import languageLabelsGetter from "./languageLabelsGetter";
import messageTypesGetter from "./messageTypesGetter";
import metricsLabelGetter from "./metricsLabelsGetter";
import nestedSchemaTypeLabelsGetter from "./nestedSchemaTypeLabelsGetter";
import notificationLabelsGetter from "./notificationLabelGetters";
import objectActionLabelsGetter from "./objectActionLabelsGetter";
import objectCategoryLabelsGetter from "./objectCategoryLabelsGetter";
import objectStatusLabelsGetter from "./objectStatusLabelsGetter";
import placeholderLabelsGetter from "./placeholderLabelsGetter";
import pushTypesGetter from "./pushTypesGetter";
import queryTypeLabelsGetter from "./queryTypeLabelsGetter";
import shortDayOfWeekLabelsGetter from "./shortDayOfWeekLabelsGetter";
import smsKeywordLabelsGetter from "./smsKeywordLabelsGetters";
import subscriptionGroupActionTriggerChannelGetter from "./subscriptionGroupActionTriggerChannelGetter";
import subscriptionGroupActionTriggerSourceGetter from "./subscriptionGroupActionTriggerSourceGetter";
import timeUnitLabelsGetter from "./timeUnitLabelsGetter";
import triggerEventLabelsGetter from "./triggerEventLabelGetters";
import warningLabelsGetter from "./warningLabelsGetter";

// Aliased for clarity
export type LocalizedLabel = string;

/**
 * A callable returning an object that maps labels to the localized label
 */
export interface LabelProvider<Trans, Options extends string> {
  (t: Trans): { [Label in Options]: LocalizedLabel };
}

/**
 * A callable object which has Label -> Localized Label attribute, value pairs
 * @example
 * const labelGetter = (option: "Foo" | "Bar") => "Baz"
 * labelGetter["Foo"] = "Baz"
 * labelGetter["Bar"] = "Baz"
 */
export type LabelGetter<Options extends string> = {
  [Label in Options]: LocalizedLabel;
} & {
  (option: Options, unknownLabelLogger?: (value: string) => void): LocalizedLabel;
};

/**
 * Logs an an unknown label error to sentry. This function is meant to resolve issues that may
 * arise at runtime with misspelled labels.
 * @param value some unknown label passed by a caller
 */
const logUnknownLabelMessage = (value: string) => {
  const message = `No localized label was found for ${value}`;
  console.error(message);
  withScope(() => {
    setTag("featureId", "localizedLabelsProvider");
    captureMessage(message, Severity.Error);
  });
};

/**
 * Prepares a label getter for the input label provider (A callable returning a record-like object)
 * @param labelProvider function returning a mapping of label to localized label value
 * @returns callable object extending the label getter
 */
const labelGetterFactory =
  <Trans, Options extends string>(labelProvider: LabelProvider<Trans, Options>) =>
  (t: Trans) => {
    const labels = labelProvider(t);
    const getter = (value: Options, logger = logUnknownLabelMessage) => {
      const label = labels[value];
      if (label) {
        return label;
      }
      if (logger) {
        logger(value);
      }
      return value;
    };
    return Object.assign(getter, labels);
  };

/**
 * This object represents an explicit interface to use the private getter functions of this module. Each method
 * on this class represents some convenient way of accessing translations from a specific namespace.
 *
 * The getter methods are callable objects that extend the return value of a label provider. Possible labels for
 * the label method can be retrieved using `Object.keys`
 *
 * @example
 * // List all possible action labels
 * Object.keys(LocalizedLabelsProvider.getActionLabels(i18n.t));
 *
 * @example
 * // Prepare a label getter
 * const metricLabel = LocalizedLabelsProvider.getMetricLabels(i18n.t);
 * console.log(metricLabel("Sends"));
 *
 * @example
 * // Localized values can also be accessed using `["<label here>"]`
 * const metricLabel = LocalizedLabelsProvider.getMetricLabels(i18n.t);
 * console.log(metricLabel["Sends"]);
 *
 * The above is not recommended however, since it does not have built in handling for unknown label inputs.
 *
 * If one of the getter functions encounters an unknown input, an error is logged to sentry. If this behavior is not
 * desireable, it can be disabled by passing `undefined` as the second parameter of a label getter. You could also
 * provide your own logging function as the second argument.
 *
 * @example
 * const metricLabel = LocalizedLabelsProvider.getMetricLabels(i18n.t);
 * // Logs to sentry
 * metricLabel("Clearly an invalid label");
 * // Does not log to sentry
 * metricLabel("Clearly an invalid label", undefined);
 * // Custom logging
 * metricLabel("Clearly an invalid label", (label) => window.alert(`Unknown label: ${label}`));
 */
const LocalizedLabelsProvider = {
  getActionLabels: labelGetterFactory(actionLabelsGetter),
  getAttributeTypeLabels: labelGetterFactory(attributeTypeLabelsGetter),
  getAudienceLabels: labelGetterFactory(audienceLabelsGetter),
  getBreakdownLabels: labelGetterFactory(breakdownLabelsGetter),
  getChannelLabels: labelGetterFactory(channelsLabelsGetter),
  getCampaignTypeLabels: labelGetterFactory(campaignTypeLabelsGetter),
  getEntryScheduleLabels: labelGetterFactory(entryScheduleLabelsGetter),
  getMessageTypeLabels: labelGetterFactory(messageTypesGetter),
  getPushTypeLabels: labelGetterFactory(pushTypesGetter),
  getConversionLabels: labelGetterFactory(conversionLabelsGetter),
  getDeliveryDetailsLabels: labelGetterFactory(deliveryDetailsLabelsGetter),
  getErrorLabels: labelGetterFactory(errorLabelsGetter),
  getGenderLabels: labelGetterFactory(genderLabelsGetter),
  getLanguageLabels: labelGetterFactory(languageLabelsGetter),
  getMetricLabels: labelGetterFactory(metricsLabelGetter),
  getPlaceholderLabels: labelGetterFactory(placeholderLabelsGetter),
  getSMSKeywordLabels: labelGetterFactory(smsKeywordLabelsGetter),
  getWarningLabels: labelGetterFactory(warningLabelsGetter),
  getTimeUnitLabels: labelGetterFactory(timeUnitLabelsGetter),
  getNotificationLabels: labelGetterFactory(notificationLabelsGetter),
  getCanvasStepsLabels: labelGetterFactory(canvasStepsLabelsGetter),
  getCanvasStepsLongLabels: labelGetterFactory(canvasStepsLongLabelsGetter),
  getTriggerEventLabels: labelGetterFactory(triggerEventLabelsGetter),
  getObjectCategoryLabels: labelGetterFactory(objectCategoryLabelsGetter),
  getObjectStatusLabels: labelGetterFactory(objectStatusLabelsGetter),
  getQueryTypeLabels: labelGetterFactory(queryTypeLabelsGetter),
  getDayOfWeekLabels: labelGetterFactory(dayOfWeekLabelGetter),
  getShortDayOfWeekLabels: labelGetterFactory(shortDayOfWeekLabelsGetter),
  getKpiLabels: labelGetterFactory(kpisLabelsGetter),
  getObjectActionLabels: labelGetterFactory(objectActionLabelsGetter),
  getNestedSchemaTypeLabels: labelGetterFactory(nestedSchemaTypeLabelsGetter),
  getTranslatedSubscriptionGroupActionTriggerSourceLabels: labelGetterFactory(
    subscriptionGroupActionTriggerSourceGetter
  ),
  getTranslatedSubscriptionGroupActionTriggerChannelLabels: labelGetterFactory(
    subscriptionGroupActionTriggerChannelGetter
  ),
} as const;

export default LocalizedLabelsProvider;
